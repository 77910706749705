<template lang="">
  <div class="top">
    <div class="l">
      <div class="pic animate__animated animate__fadeInLeft">
        <img :src="siteList.company_logo" alt="">
      </div>
      <div class="r-item">
        <ul class="top_nav  animate__animated animate__fadeIn">
          <li @click="changePath('/')" class="top_item"><span>首页</span></li>
          <li @mouseleave="changeTab(null)" @mouseover="changeTab(item.id)" class="top_item"
            v-for="(item,index) in categoryList" :key="item.id">
            <span :class="{'top_active':isActive(item.path)}" @click="changePath(item.path)">{{item.title}}</span>
            <div v-show="currentSec===item.id&&item.subItem[0].category_id!==null" class="triangle"></div>
            <div v-show="currentSec===item.id&&item.subItem[0].category_id!==null" class="son_tab">
              <ul>
                <li @click="changePath(item.path,item2.sec_path)" @mouseover="changeSec(index)"
                  @mouseleave="changeSec(null)" v-for="(item2,index) in item.subItem">
                  <span :class="{on:isSec===index}">{{item2.sec_title}}</span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="r">
      <div class="r-container">
        <span>机智鸟热线</span>
        <span>{{siteList.contact_phone}}</span>
        <button @click="scrollToBottom">免费咨询</button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        currentPath: '',
        showSec: false,
        currentSec: null,
        isSec: null,
        banner: ''
      }
    },
    created() {
    },
    props: ['categoryList', 'siteList'],
   
    methods: {
      isActive(path) {
        return this.$route.path === path;
      },
      changePath(path, path2) {
        if (path2) {
          this.$router.push({ path: path2 })
        } else {
          // let newPath = this.categoryList.filter(item => item.path === '/product')[0]
          // this.$router.push({ path: path !== '/product' || path !== '/news' ? path : newPath.subItem[0].sec_path })
          this.$router.push({ path });
        }
      },
      changeTab(item) {
        this.currentSec = item
      },
      changeSec(item) {
        this.isSec = item
      },
      scrollToBottom() {
        const targetElements = document.getElementsByClassName('submit_form');
        if (targetElements.length > 0) {
          targetElements[0].scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
  }
</script>
<style lang="scss" scoped>

</style>