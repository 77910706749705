<template lang="">
  <div class="consult">
    <div class="consult_content">
      <div>
        <div >
          <img src="@/assets/img/contact_title1.png" alt=""><h1>{{consultTitle}}</h1><img src="@/assets/img/contact_title2.png" alt="">
        </div>
        <h3>{{smallTitle}}</h3>
      </div>
      <div class="submit_form">
        <h2>立即领取企业福利 预约您的专属顾问</h2>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="" class="demo-ruleForm">
          <el-form-item label="" prop="name">
            <el-input v-model="ruleForm.name">
              <template slot="prefix">您的姓名 <span style="color: red;">*</span></template>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="phone">
            <el-input v-model="ruleForm.phone">
              <template slot="prefix">联系方式 <span style="color: red;">*</span></template>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="company">
            <el-input v-model="ruleForm.company">
              <template slot="prefix">公司名称 <span style="color: red;">*</span></template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-form-item label="" prop="area">
              <el-select class="sign_more_chose" v-model="ruleForm.area" placeholder="请选择" clearable>
                <template slot="prefix">所属地区 <span style="color: red;">*</span></template>
                <el-option v-for="(item,index) in options" :key="item.index" :label="item.title" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
          <p  @click="changeLook" style="cursor: pointer;" class="p3"><input style="margin-right: 10px;" name="" v-model="isLook" type="checkbox" value="" checked="">我已知晓 <a 
              javascript="avid:;">《个人信息收集与隐私保护声明》</a></p>
        </el-form>
        <button @click="submit">免费咨询</button>
        <div class="consult_phone">
          <i class="iconfont icon-dianhua1"></i>
          <div>
            <span>资讯热线: </span>
            <span>{{siteList.contact_phone}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import areaData from 'china-area-data';
  export default {
    data() {
      return {
        ruleForm: {},
        isLook: false,
        rules: {
          name: [{ required: true, message: '您的姓名', trigger: 'blur' }],
          phone: [{ required: true, message: '联系方式', trigger: 'blur' }],
          company: [{ required: true, message: '公司名称', trigger: 'blur' }],
          area: [{ required: true, message: '所属地区', trigger: 'blur' }],
        },
        options: [
          { "title": "北京市", "value": "北京市" },
          { "title": "上海市", "value": "上海市" },
          { "title": "天津市", "value": "天津市" },
          { "title": "重庆市", "value": "重庆市" },
          { "title": "河北省", "value": "河北省" },
          { "title": "山西省", "value": "山西省" },
          { "title": "辽宁省", "value": "辽宁省" },
          { "title": "吉林省", "value": "吉林省" },
          { "title": "黑龙江省", "value": "黑龙江省" },
          { "title": "江苏省", "value": "江苏省" },
          { "title": "浙江省", "value": "浙江省" },
          { "title": "安徽省", "value": "安徽省" },
          { "title": "福建省", "value": "福建省" },
          { "title": "江西省", "value": "江西省" },
          { "title": "山东省", "value": "山东省" },
          { "title": "河南省", "value": "河南省" },
          { "title": "湖北省", "value": "湖北省" },
          { "title": "湖南省", "value": "湖南省" },
          { "title": "广东省", "value": "广东省" },
          { "title": "海南省", "value": "海南省" },
          { "title": "四川省", "value": "四川省" },
          { "title": "贵州省", "value": "贵州省" },
          { "title": "云南省", "value": "云南省" },
          { "title": "陕西省", "value": "陕西省" },
          { "title": "甘肃省", "value": "甘肃省" },
          { "title": "青海省", "value": "青海省" },
          { "title": "内蒙古自治区", "value": "内蒙古自治区" },
          { "title": "广西壮族自治区", "value": "广西壮族自治区" },
          { "title": "西藏自治区", "value": "西藏自治区" },
          { "title": "宁夏回族自治区", "value": "宁夏回族自治区" },
          { "title": "新疆维吾尔自治区", "value": "新疆维吾尔自治区" },
          { "title": "香港特别行政区", "value": "香港特别行政区" },
          { "title": "澳门特别行政区", "value": "澳门特别行政区" }
        ]
      }
    },
    props:['siteList','consultTitle','smallTitle'],
    methods: {
      async submit() {
        if (this.isLook) {
          await this.$util.validate(this.$refs.ruleForm);
          let res = await this.$request.post("contact/add", this.ruleForm);
          this.loading = false;
          if (res.data.code === 0) {
            this.$message.success("提交成功，客服会尽快联系您~");
            // this.$router.push('/')
            this.ruleForm = {}
          }
        }else{
          alert('请先勾选协议')
        }
      },
      changeLook() {
        this.isLook = !this.isLook
      }
    },
  }
</script>
<style lang="">

</style>