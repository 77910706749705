<template>
  <el-dialog :lockScroll="false" title="扫码立即联系" :visible.sync="dialogVisible" width="500px" style="text-align: center;">
    <div style="text-align: center;">
      <!-- 图片 -->
      <img src="@/assets/img/more_code.png" alt="图片描述" style="width: 200px; ">
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      consultationForm: {
        company_name: '',
        contact_phone: '',
        contact_name: '',
        agreeProtocol: false
      },
      
      rules: {
        contact_phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        agreeProtocol: [
          { required: true, message: '请勾选协议', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    
    async submitConsultation() {
      if (this.consultationForm.agreeProtocol) {
        this.$refs.consultationForm.validate(async valid => {
        if (valid) {
          let res = await this.$request.post('companyverify/add',this.consultationForm)
          if (res.data.code===0) {
            this.$message.success("提交成功")
          }
          // 发送请求...
          this.dialogVisible = false;
        } else {
          alert('提交失败，联系电话不能为空');
          return false;
        }
      });
      }else{
        alert('请勾选协议')
      }
    }
  }
};
</script>

<style lang="css" scoped>
   ::v-deep .el-dialog{
    height: 400px;
  }
  .l{
    position: relative;
  }
 ::v-deep .el-input__inner{
  width: 420px !important;
 }
 .el-button--primary{
  background: #F58634 !important;
  border-color: #f58634 !important;
 }
 ::v-deep .safety2{
  color: #1ad2a0;
 }
 .r .text .dl1{
 }
 ::v-deep .r .text .dl1 dt{
  line-height: 52px;
  font-size: 14px;
 }
 ::v-deep .r .text .dl1 dd{
  line-height: 36px;
  color: #8a8a8a;
 }
</style>